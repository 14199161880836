import validator from "validator"

export const validatePhoneMiniUser = (value, setPhoneValid) => {
  const newValue = "+" + value
  const unformattedMobile = newValue.replace(/\s+/g, "")
  if (value.length === 2 || value.length === 0) {
    setPhoneValid(true)
    return true
  } else if (!validator.isMobilePhone(unformattedMobile, "any")) {
    setPhoneValid(false)
    return false
  }
  setPhoneValid(true)
  return true
}
