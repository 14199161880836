import axios from "axios"
import { authToken } from "../Security/authToken"

export const fetchApi = async (url, account, inProgress, instance) => {
  const accessToken = await authToken(account, inProgress, instance)

  const response = axios.get(url, {
    headers: {
      "Conent-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  })

  return response
}

export const fetchApiAnonymous = url => {
  const response = axios.get(url, {
    headers: {
      "Conent-Type": "application/json",
    },
  })

  return response
}

export const postApi = async (url, obj, account, inProgress, instance) => {
  const accessToken = await authToken(account, inProgress, instance)

  const response = axios.post(url, obj, {
    headers: {
      "Conent-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
      Authorization: "Bearer " + accessToken,
    },
  })

  return response
}

export const postApiAnonymous = (url, obj) => {
  const response = axios.post(url, obj, {
    headers: {
      "Conent-Type": "application/json",
    },
  })

  return response
}

export const patchApi = async (url, obj, account, inProgress, instance) => {
  const accessToken = await authToken(account, inProgress, instance)

  const response = axios.patch(url, obj, {
    headers: {
      "Conent-Type": "application/json",
      "Ocp-Apim-Subscription-Key": process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
      Authorization: "Bearer " + accessToken,
    },
  })

  return response
}

export const patchApiAnonymous = (url, obj) => {
  const response = axios.patch(url, obj, {
    headers: {
      "Conent-Type": "application/json",
    },
  })

  return response
}

export const deleteApi = async (url, account, inProgress, instance) => {
  const accessToken = await authToken(account, inProgress, instance)

  const response = axios.delete(url, {
    headers: {
      "Conent-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  })

  return response
}

export const deleteApiAnonymous = url => {
  const response = axios.delete(url, {
    headers: {
      "Conent-Type": "application/json",
    },
  })

  return response
}
