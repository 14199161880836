import React from "react"

export const CheckAge = (ssn, setter) => {
  const day: string = ssn?.substring(0, 2)
  const month: string = ssn?.substring(2, 4)
  const yearString: string = ssn?.substring(4, 6)
  const individualNumber: number = parseInt(ssn?.substring(6, 9))
  const yearInt: number = parseInt(yearString)
  let prefix: string = "20"
  if (individualNumber < 500) {
    prefix = "19"
  } else if (individualNumber >= 900 && yearInt >= 40) {
    prefix = "19"
  }
  const today = new Date()
  const dateString = prefix + yearString + "-" + month + "-" + day
  console.log(dateString)
  const birthDate = new Date(dateString)
  const birthDateCheckYoung = new Date(today.setMonth(today.getMonth() - 180)) // Check for under 15 years old
  const birthDateCheckOld = new Date(today.setMonth(today.getMonth() - 132)) // Check for over 26 years old (since we are setting today to be 15 years ago, we just subtract another 11 years)
  if (birthDate > birthDateCheckYoung || birthDate < birthDateCheckOld) {
    setter(true)
  }
}
