//Regular input field
export const inputField =
  "  font-sans text-sm text-black border-b-2 border-blue p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none "
//Input field error
export const inputFieldError =
  " font-sans text-sm text-black border-b-2 border-blue p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
//Regular phonenumber field
export const inputFieldPhoneNumber =
  " border-b-2 border-blue font-sans text-sm text-black hover:border-2 hover:-mb-0.5 focus:ring-2 focus:ring-blue focus:border-0 focus:outline-none "
//Input phonenumber error field
export const inputFieldPhoneNumberError =
  " border-b-2 border-blue font-sans text-sm text-black hover:border-2 hover:-mb-0.5 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
// Modal input field
export const inputFieldModal =
  " font-sans text-xs text-black border-b-2 border-blue p-2 mt-2 w-full h-8 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 peer focus:outline-none "
//Modal input field error
export const inputFieldErrorModal =
  " font-sans text-xs text-black border-b-2 border-blue p-2 mt-2 w-full h-8 hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
//Modal input phonenumber field
export const inputFieldPhoneNumberModal =
  " border-b-2 border-blue font-sans text-xs text-black hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 focus:outline-none "
//Modal input phonenumber error field
export const inputFieldPhoneNumberErrorModal =
  " border-b-2 border-blue font-sans text-xs text-black hover:border-2 focus:ring-2 focus:ring-orange-dark focus:border-0 focus:outline-none "
